import { useEffect } from 'react';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { trackFeatureFlagsVariation } from '@jsmdg/tracking';
import { HeaderMode } from '../../shared/enums/HeaderMode';
import { type HeaderContext } from '../types';
import { CheckoutHeader } from './CheckoutHeader/CheckoutHeader';
import { PromoBanner } from './PromoBanner/PromoBanner';
import { RedeemHeader } from './RedeemHeader/RedeemHeader';
import { StoreHeader } from './StoreHeader/StoreHeader';

type HeaderProps = {
    readonly headerMode: HeaderMode;
    readonly step?: number;
    readonly isPromoBannerEnabled?: boolean;
};

const getActiveHeader = (headerMode: HeaderMode, step: number): JSX.Element => {
    switch (headerMode) {
        case HeaderMode.Discovery:
            return <StoreHeader />;
        case HeaderMode.Checkout:
            return <CheckoutHeader step={step} />;
        case HeaderMode.Booking:
            return <RedeemHeader />;
        default:
            // the next line will raise error if any case is added to HeaderMode and not mentioned in the cases!
            // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = headerMode;
            return <StoreHeader />;
    }
};

const Header = ({
    headerMode,
    isPromoBannerEnabled = false,
    step = -1,
}: HeaderProps): JSX.Element => {
    const { featureFlagsVariation } = useFragmentContext<HeaderContext>();

    useEffect(() => {
        trackFeatureFlagsVariation(featureFlagsVariation);
    }, [featureFlagsVariation]);

    return (
        <>
            {isPromoBannerEnabled && <PromoBanner />}
            {getActiveHeader(headerMode, step)}
        </>
    );
};

export { Header };
export type { HeaderProps };
