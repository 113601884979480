import { type MouseEvent } from 'react';
import { useIsSSR } from 'react-aria';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventName, GA4FeatureCategory, trackCategoryTreeDisplayName } from '@jsmdg/tracking';
import { Breadcrumbs } from '@jsmdg/yoshi';
import { type BreadCrumbItemInterface } from '../../../shared/types';
import { getLinkWithLocationParams } from '../../helper/getLinkWithLocationParams';
import { trackBreadcrumbClick } from '../../helper/trackNavigation';
import { useVouchers } from '../../hooks/useVouchers';
import { type HeaderContext } from '../../types';

const messages = defineMessages({
    home: {
        defaultMessage: 'Home',
    },
    allExperiences: {
        defaultMessage: 'Alle Erlebnisse',
    },
});

const HeaderBreadcrumbs = (): JSX.Element | null => {
    const { breadcrumbs, tenantConfig, type }: HeaderContext = useFragmentContext();
    const { isThereAnyVoucher: isRedemptionFlow } = useVouchers();
    const intl = useIntl();
    const isSSR = useIsSSR();
    const baseUrl = isRedemptionFlow ? tenantConfig.urls.allExperiences : '/';
    const baseTitle = intl.formatMessage(
        isRedemptionFlow ? messages.allExperiences : messages.home,
    );

    if (!breadcrumbs) {
        return null;
    }

    if (!isSSR) {
        trackCategoryTreeDisplayName(breadcrumbs, type);
    }

    const trackBaseClick = (e: MouseEvent): void => {
        e.preventDefault();
        trackBreadcrumbClick({
            e,
            action: `Level 0`,
            label: baseTitle,
            eventData: {
                eventName: GA4EventName.ClickNavigationItem,
                feature_category: GA4FeatureCategory.BreadcrumbNavigation,
                click_element: baseTitle,
                click_text: baseTitle,
                navigationLevel: 0,
                click_url: window.location.origin + baseUrl,
            },
        });

        window.location.href = baseUrl;
    };

    const createBreadcrumb = (breadcrumb: BreadCrumbItemInterface, index: number): JSX.Element => {
        const { title, url } = breadcrumb;
        const isLastItem = index === breadcrumbs.length - 1;

        const trackingData = {
            eventName: GA4EventName.ClickNavigationItem,
            feature_category: GA4FeatureCategory.BreadcrumbNavigation,
            click_element: `${baseTitle} > ${breadcrumbs
                .slice(0, index + 1)
                .map(b => b.title)
                .join(' > ')}`,
            click_text: title,
            navigationLevel: index + 1,
        };

        const onLinkClick = (e: MouseEvent): void => {
            e.preventDefault();
            trackBreadcrumbClick({
                e,
                action: `Level ${index + 1}`,
                label: title,
                eventData: {
                    ...trackingData,
                    click_url: window.location.origin + url,
                },
            });

            window.location.href = getLinkWithLocationParams(url);
        };

        return (
            <Breadcrumbs.Item
                key={url}
                href={url}
                onClick={onLinkClick}
                isLastItem={isLastItem}
                className={classNames({
                    'd-none d-md-block': isLastItem,
                })}
            >
                {title}
            </Breadcrumbs.Item>
        );
    };

    return (
        <Breadcrumbs className="container mt-2x">
            <Breadcrumbs.Item href={baseUrl} onClick={trackBaseClick}>
                {baseTitle}
            </Breadcrumbs.Item>

            {breadcrumbs.map(createBreadcrumb)}
        </Breadcrumbs>
    );
};

export { HeaderBreadcrumbs };
