import { type PropsWithChildren, useEffect, useState } from 'react';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type RedemptionInformation } from '../../../shared/types';
import { RedemptionContext } from '../../context/RedemptionContext';
import { useCartContext } from '../../hooks/useCartContext';
import { type HeaderContext } from '../../types';

const RedemptionContextProvider = ({ children }: PropsWithChildren): JSX.Element => {
    const { redemptionInformation: initialRedemptionInformation } =
        useFragmentContext<HeaderContext>();
    const [redemptionInformation, setRedemptionInformation] = useState<RedemptionInformation>(
        initialRedemptionInformation,
    );
    const cart = useCartContext();

    useEffect(() => {
        if (cart?.vouchers) {
            setRedemptionInformation(prev => ({
                ...prev,
                vouchers: cart.vouchers,
                vouchersTotal: cart.vouchersTotal,
                vouchersCount: cart.vouchers.length,
            }));
        }
    }, [cart]);

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <RedemptionContext.Provider value={{ redemptionInformation, setRedemptionInformation }}>
            {children}
        </RedemptionContext.Provider>
    );
};

export { RedemptionContextProvider };
