import { useCartContext } from './useCartContext';

const useVouchers = (): {
    isThereAnyVoucher: boolean;
} => {
    const cart = useCartContext();

    const isThereAnyVoucher = !!cart?.vouchersCount;

    return { isThereAnyVoucher };
};

export { useVouchers };
