import { startTransition, useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Box, Breakpoint, Image, useBreakpoint } from '@jsmdg/yoshi';
import BallonIcon from '@jsmdg/yoshi/dist/components/Icons/svg/ballon.svg';
import HalfClockIcon from '@jsmdg/yoshi/dist/components/Icons/svg/halfClock.svg';
import HeadsetIcon from '@jsmdg/yoshi/dist/components/Icons/svg/headset.svg';
import OliveLeafIcon from '@jsmdg/yoshi/dist/components/Icons/svg/oliveLeaf.svg';
import { type HeaderContext } from '../../types';
import styles from './PromoBanner.module.scss';

const messages = defineMessages({
    textVarityJS: {
        defaultMessage: 'Ausgezeichneter Kundenservice',
    },
    textVarityMD: {
        defaultMessage: '20 Jahre Erlebnisgeschenk-Experte',
    },
});

const PromoBannerItem = ({
    active,
    className,
    icon,
    text,
    wasActive,
}: {
    readonly icon: string;
    readonly text: string;
    readonly className?: string;
    readonly active: boolean;
    readonly wasActive: boolean;
}): JSX.Element => (
    <li
        className={classNames(className, styles.promoBannerItem, 'd-flex align-items-center')}
        data-active={active}
        data-was-active={wasActive}
    >
        <Image
            className={classNames(styles.icons, 'mr-xs-1x mr-0-5x')}
            src={icon}
            alt=""
            lazyLoad={false}
            width={20}
            height={20}
            fetchpriority="low"
        />
        <span>{text}</span>
    </li>
);

const PromoBanner = (): JSX.Element => {
    const { isMydays, tenantConfig }: HeaderContext = useFragmentContext();
    const [currentIndex, setCurrentIndex] = useState(0);
    const isMobile = !useBreakpoint(Breakpoint.SM);
    const intl = useIntl();
    const promoBannerItems = [
        { icon: isMydays ? BallonIcon : OliveLeafIcon, text: tenantConfig.uspClaim },
        {
            icon: isMydays ? OliveLeafIcon : HalfClockIcon,
            text: isMydays
                ? intl.formatMessage(messages.textVarityMD)
                : tenantConfig.uspValidityText,
        },
        {
            icon: isMydays ? HalfClockIcon : HeadsetIcon,
            text: isMydays
                ? tenantConfig.uspValidityText
                : intl.formatMessage(messages.textVarityJS),
            className: 'd-sm-none d-md-flex',
        },
    ];

    const handleSlideChange = useCallback(() => {
        startTransition(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % promoBannerItems.length);
        });
    }, [promoBannerItems.length]);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (isMobile) {
            interval = setInterval(handleSlideChange, 3_000);
        }

        return () => clearInterval(interval);
    }, [handleSlideChange, isMobile]);

    return (
        <Box
            boxWrapperClassName={styles.promoBannerWrapper}
            className={classNames(
                styles.promoBanner,
                'd-flex w-100 text-nowrap position-relative overflow-hidden',
            )}
        >
            <ul
                className={classNames(
                    styles.promoBannerItemsContainer,
                    'd-flex justify-content-xs-between w-100 m-0 p-0 position-relative',
                )}
            >
                {promoBannerItems.map((item, index) => (
                    <PromoBannerItem
                        key={item.text}
                        {...item}
                        active={currentIndex === index}
                        wasActive={
                            isMobile &&
                            (currentIndex === index + 1 ||
                                (currentIndex === 0 && index === promoBannerItems.length - 1))
                        }
                    />
                ))}
            </ul>
        </Box>
    );
};

export { PromoBanner };
