import { type Dispatch, type SetStateAction, useRef, useState } from 'react';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type GA4ClickEvent } from '@jsmdg/tracking/dist/events';
import { type NavigationItem } from '../../shared/types';
import { availableTrackingActions, trackTopNavigationHover } from '../helper/trackNavigation';
import { type HeaderContext } from '../types';
import { useVouchers } from './useVouchers';

function useHoverTracking(
    depth: number,
    maxDepth: number,
): {
    topNavigationItems: NavigationItem[];
    trackDesktopNavigationHoverIn: (
        category: NavigationItem,
        trackingLabel?: string,
        eventData?: GA4ClickEvent,
    ) => void;
    setIsFromTouchEvent: Dispatch<SetStateAction<boolean>>;
} {
    const { topNavigationItems, topRedemptionNavigationItems }: HeaderContext =
        useFragmentContext();
    const canTrackHoverOut = useRef<boolean>(false);
    const intendedHoverTimeout = useRef<ReturnType<typeof setTimeout>>();
    const { isThereAnyVoucher } = useVouchers();
    const navigation = isThereAnyVoucher ? topRedemptionNavigationItems : topNavigationItems;

    /**
     * In order to not track hover events when the user uses a touch device
     * we set the state isFromTouchEvent to true when a touchstart event was fired.
     * A touchstart event is always triggered before the mouseenter event.
     */
    const [isFromTouchEvent, setIsFromTouchEvent] = useState(false);

    function canTrackHover(item: NavigationItem): boolean {
        return depth === 0 || Boolean(item.children && item.children.length && depth <= maxDepth);
    }

    function timeoutHandler(
        item: NavigationItem,
        trackingLabel?: string,
        eventData?: GA4ClickEvent,
    ): void {
        if (intendedHoverTimeout.current) {
            canTrackHoverOut.current = true;
            trackTopNavigationHover(
                availableTrackingActions.HOVER_IN,
                item,
                trackingLabel,
                eventData,
            );
        }
    }

    return {
        topNavigationItems: navigation,
        trackDesktopNavigationHoverIn: (
            category: NavigationItem,
            trackingLabel?: string,
            eventData?: GA4ClickEvent,
        ): void => {
            if (!isFromTouchEvent && canTrackHover(category)) {
                intendedHoverTimeout.current = setTimeout(
                    () => timeoutHandler(category, trackingLabel, eventData),
                    500,
                );
            } else {
                setIsFromTouchEvent(false);
            }
        },
        setIsFromTouchEvent,
    };
}

export { useHoverTracking };
