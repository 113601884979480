import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import {
    Button,
    ButtonColor,
    ButtonShape,
    ButtonSize,
    ButtonVariant,
    CloseIcon,
} from '@jsmdg/yoshi';
import styles from './CloseButton.module.scss';

const messages = defineMessages({
    clearSearch: {
        defaultMessage: 'Löschen',
    },
});

type CloseButtonProps = {
    readonly handleOnClick: () => void;
    readonly isNewStage?: boolean;
};

const CloseButton = ({ handleOnClick, isNewStage }: CloseButtonProps): JSX.Element => {
    const intl = useIntl();

    return (
        <Button
            size={ButtonSize.Small}
            variant={ButtonVariant.Ghost}
            color={ButtonColor.Dark}
            shape={ButtonShape.Icon}
            className={classNames('mr-md-0-5x', {
                [styles.newStageButton]: isNewStage,
            })}
            onClick={handleOnClick}
            a11yText={intl.formatMessage(messages.clearSearch)}
        >
            <CloseIcon />
        </Button>
    );
};

export { CloseButton };
