import { startTransition, useEffect, useState } from 'react';
import loadable from '@loadable/component';
import classNames from 'classnames';

import { ObjectType, useAlgoliaError } from '@jsmdg/algolia-helpers';
import { getLastSeenProductIdsStorage } from '@jsmdg/browser-storage';
import { logToSentry, useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { trackShowSearchPopup } from '@jsmdg/tracking';
import { useBreakpoint } from '@jsmdg/yoshi';
import { Breakpoint } from '@jsmdg/yoshi/dist/enums/breakpoint';
import { type Page, type Product } from '../../../shared/types';
import { getSearchHistory } from '../../helper/searchHistory';
import { useActivateListItemWithDelay } from '../../hooks/useActivateListItemWithDelay';
import { useDebouncedState } from '../../hooks/useDebouncedState';
import { useRedemptionContext } from '../../hooks/useRedemptionContext';
import { useSearchResults } from '../../hooks/useSearchResults';
import { type HeaderContext } from '../../types';
import { BurgerNavigationFallback } from '../BurgerNavigation/BurgerNavigation/BurgerNavigationFallback';
import { CartButton } from '../CartButton/CartButton';
import { CustomerAccountButton } from '../CustomerAccountButton/CustomerAccountButton';
import { Logo } from '../Logo';
import { RedeemButton } from '../RedeemButton/RedeemButton';
import { SearchBar } from '../SearchBar/SearchBar';
import { ToggleSearchButton } from '../ToggleSearchButton/ToggleSearchButton';
import { ToggleSearchField } from '../ToggleSearchField';
import { WishlistButton } from '../WishlistButton';
import styles from './ActionHeader.module.scss';

const SearchResultSize = {
    pages: {
        mobile: 5,
        desktop: 7,
    },
    products: {
        mobile: 2,
        desktop: 3,
    },
};

type ActionHeaderProps = {
    readonly onFullscreenChange: (value: boolean) => void;
    readonly searchBarOnly?: boolean;
    readonly isNewStage?: boolean;
};

const LoadableCustomerAccountFlyout = loadable(
    async () => import('../CustomerAccountFlyout/CustomerAccountFlyout'),
);
const LoadableVoucherModal = loadable(async () => import('../VoucherModal/VoucherModal'));
const LoadableCustomerWelcomeFlyout = loadable(
    async () => import('../CustomerWelcomeFlyout/CustomerWelcomeFlyout'),
);
const LoadableBurgerNavigation = loadable(
    async () => import('../BurgerNavigation/BurgerNavigation'),
    {
        fallback: <BurgerNavigationFallback layoutClassName="d-md-none mr-1x" />,
    },
);

const ActionHeader = ({
    isNewStage,
    onFullscreenChange,
    searchBarOnly,
}: ActionHeaderProps): JSX.Element => {
    const { featureFlagsVariation, isLoggedIn, isMydays, isNewCustomer, searchTerm } =
        useFragmentContext<HeaderContext>();
    const { redemptionInformation } = useRedemptionContext();
    const [query, updateQuery] = useDebouncedState(searchTerm);
    const algoliaError = useAlgoliaError();

    const [currentSearchTerm, setCurrentSearchTerm] = useState(searchTerm);
    const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
    const [isWelcomeFlyoutDisabled, setIsWelcomeFlyoutDisabled] = useState(!isNewCustomer);
    const isDesktop = useBreakpoint(Breakpoint.MD);
    const [showFlyout, setShowFlyout] = useState(false);
    const [showFlyoutDesktop, setShowFlyoutDesktop] = useState(false);
    const isNotSmallScreen = useBreakpoint(Breakpoint.XS);
    const isTabletScreen = useBreakpoint(Breakpoint.SM);

    const productResults = useSearchResults<Product>({
        objectType: ObjectType.Product,
        searchTerm: query,
        attributesToRetrieve: [
            'productId',
            'title',
            'url',
            'images',
            'locations',
            'rating',
            'originalPrice',
            'price',
            'manufacturerSku',
            'trackingName',
            'trackingPath',
            'productType',
        ],
        attributesToHighlight: ['title'],
        size: isDesktop ? SearchResultSize.products.desktop : SearchResultSize.products.mobile,
    });

    const pageResults = useSearchResults<Page>({
        objectType: ObjectType.Page,
        searchTerm: query,
        attributesToRetrieve: ['title', 'url', 'numberOfProducts'],
        attributesToHighlight: ['title'],
        size: isDesktop ? SearchResultSize.pages.desktop : SearchResultSize.pages.mobile,
    });

    const { activeItemIndex, resetActiveItemIndexWithDelay, setActiveItemIndexWithDelay } =
        useActivateListItemWithDelay();
    const showCustomerAccountFlyout =
        activeItemIndex !== -1 && !redemptionInformation?.isEnabled && isNotSmallScreen;
    const showVoucherModal =
        activeItemIndex !== -1 && redemptionInformation?.isEnabled && !isTabletScreen;

    const handleSearch = (inputSearchTerm: string): void => {
        startTransition(() => {
            updateQuery(inputSearchTerm?.trim().slice(0, 100));
        });
    };

    const handleShowFlyout = (show: boolean): void => {
        if (isDesktop) {
            setShowFlyoutDesktop(show);
            if (show) {
                document.body.classList.add('position-relative');
            } else {
                document.body.classList.remove('position-relative');
            }
        } else {
            setShowFlyout(show);
        }
    };

    const toggleSearch = (): void => {
        const newIsOpen = !isMobileSearchOpen;
        setIsMobileSearchOpen(newIsOpen);
        onFullscreenChange(newIsOpen);

        if (!isDesktop) {
            if (newIsOpen) {
                document.body.classList.add('overflow-hidden');
            } else {
                document.body.classList.remove('overflow-hidden');
            }
        }
    };

    const openHandler = () => {
        setIsMobileSearchOpen(true);
    };

    const handleCustomerAccountButtonClick = (show: boolean): void => {
        const redirectTo = isLoggedIn ? '/account' : '/login';

        if (!redemptionInformation?.isEnabled && (isLoggedIn || !isNotSmallScreen)) {
            window.location.href = redirectTo;
            return;
        }

        if (show) {
            setActiveItemIndexWithDelay(1);
        } else {
            resetActiveItemIndexWithDelay();
        }
    };

    const handleOnClick = (): void => {
        handleCustomerAccountButtonClick(true);
    };

    const handleMouseEnter = (): void => {
        if (redemptionInformation?.isEnabled) return;
        setActiveItemIndexWithDelay(1);
    };

    const handleMouseLeave = (): void => {
        if (redemptionInformation?.isEnabled) return;
        resetActiveItemIndexWithDelay();
    };

    useEffect(() => {
        if (showFlyout || showFlyoutDesktop) {
            trackShowSearchPopup(
                getSearchHistory().length > 0,
                getLastSeenProductIdsStorage().get().length > 0,
            );
        }
    }, [showFlyout, showFlyoutDesktop]);

    useEffect(() => {
        if (algoliaError?.message) {
            logToSentry(algoliaError);
        }
    }, [algoliaError]);

    return (
        <>
            <div
                className={classNames(
                    'd-flex flex-wrap flex-sm-nowrap align-items-center py-1-5x py-xs-2x',
                    {
                        'pt-md-2-5x': isMydays && featureFlagsVariation?.guided_search === 'on',
                    },
                )}
            >
                {!searchBarOnly && (
                    <>
                        <LoadableBurgerNavigation layoutClassName="d-md-none mr-1x" />
                        <Logo className="mr-auto mr-sm-0" href="/" isOnMdBreakpoint={false} />
                    </>
                )}
                {(searchBarOnly || !isNewStage) && (
                    <div
                        className={classNames('d-md-flex flex-fill', {
                            'd-none': !isNewStage,
                        })}
                    >
                        <SearchBar
                            serpUrl="/search"
                            searchTerm={currentSearchTerm}
                            changedHandler={setCurrentSearchTerm}
                            layoutClassName="mx-md-4x mx-lg-6x"
                            productResults={productResults}
                            pageResults={pageResults}
                            isA11yHidden={!isDesktop}
                            showFlyoutDesktop={showFlyoutDesktop}
                            handleShowFlyout={handleShowFlyout}
                            handleSearch={handleSearch}
                            isMobileSearchOpen={isMobileSearchOpen}
                            algoliaError={algoliaError}
                            isNewStage={isNewStage}
                            isDesktop={isDesktop}
                            openHandler={openHandler}
                        />
                    </div>
                )}
                {!searchBarOnly && (
                    <>
                        <RedeemButton layoutClassName="d-none d-sm-inline-flex ml-sm-auto mr-sm-3x mr-md-3x text-nowrap" />
                        <ToggleSearchButton
                            toggleSearch={toggleSearch}
                            layoutClassName={classNames(styles.icon, 'd-none d-md-none', {
                                ' d-sm-flex ': !isNewStage,
                            })}
                        />
                        <div className="position-relative ml-sm-1-5x">
                            <CustomerAccountButton
                                highlighted={!isWelcomeFlyoutDisabled && isLoggedIn}
                                layoutClassName={classNames(styles.icon)}
                                onClick={handleOnClick}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            />
                            {showCustomerAccountFlyout && (
                                <LoadableCustomerAccountFlyout
                                    mouseEnter={() => setActiveItemIndexWithDelay(1)}
                                    requestClose={() => resetActiveItemIndexWithDelay()}
                                />
                            )}

                            {showVoucherModal && (
                                <LoadableVoucherModal
                                    mouseEnter={() => setActiveItemIndexWithDelay(1)}
                                    onRequestClose={() => resetActiveItemIndexWithDelay()}
                                />
                            )}

                            {isLoggedIn && !isWelcomeFlyoutDisabled && (
                                <LoadableCustomerWelcomeFlyout
                                    requestClose={() => {
                                        setIsWelcomeFlyoutDisabled(true);
                                    }}
                                />
                            )}
                        </div>
                        <WishlistButton layoutClassName={classNames(styles.icon, 'ml-1x')} />
                        <CartButton layoutClassName={classNames(styles.icon, 'ml-1x')} />
                    </>
                )}
                {(searchBarOnly || !isNewStage) && (
                    <ToggleSearchField
                        searchTerm={currentSearchTerm}
                        toggleSearch={toggleSearch}
                        layoutClassName={classNames('mt-1x d-sm-none d-md-none', {
                            'd-none': isNewStage,
                        })}
                    />
                )}
            </div>
            {isMobileSearchOpen && (
                <div className="d-md-none">
                    <SearchBar
                        serpUrl="/search"
                        searchTerm={currentSearchTerm}
                        closeHandler={toggleSearch}
                        changedHandler={setCurrentSearchTerm}
                        layoutClassName="mx-md-4x mx-lg-6x"
                        productResults={productResults}
                        pageResults={pageResults}
                        isA11yHidden={!isDesktop}
                        showFlyout={showFlyout}
                        handleShowFlyout={handleShowFlyout}
                        handleSearch={handleSearch}
                        isMobileSearchOpen={isMobileSearchOpen}
                        isNewStage={isNewStage}
                        isDesktop={isDesktop}
                    />
                </div>
            )}
        </>
    );
};

export { ActionHeader };
