import { useState } from 'react';
import { useIsSSR } from 'react-aria';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { AlgoliaContextProvider } from '@jsmdg/algolia-helpers';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Box, Image } from '@jsmdg/yoshi';
import stageImage from '../../assets/stage.jpg';
import { useAlgoliaClient } from '../../hooks/useAlgoliaClient';
import { type HeaderContext } from '../../types';
import { ActionHeader } from '../ActionHeader/ActionHeader';
import { Backdrop } from '../Backdrop';
import { BreadcrumbMarkup } from '../BreadcrumbMarkup/BreadcrumbMarkup';
import { CartContextProvider } from '../CartContextProvider';
import { DesktopNavigation } from '../DesktopNavigation/DesktopNavigation';
import { HeaderBreadcrumbs } from '../HeaderBreadcrumbs';
import { OrganisationMarkup } from '../OrganisationMarkup';
import { RedemptionContextProvider } from '../RedemptionContextProvider';
import { TopHeader } from '../TopHeader/TopHeader';
import styles from './StoreHeader.module.scss';

const StoreHeader = (): JSX.Element => {
    const [fullscreenOpen, setFullscreenOpen] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const { breadcrumbs, featureFlagsVariation, isMydays, showBreadcrumbs }: HeaderContext =
        useFragmentContext();
    const isSSR = useIsSSR();
    const searchClient = useAlgoliaClient();

    const canRenderBreadcrumbMarkup = !isSSR && !!breadcrumbs;
    const canRenderBreadcrumbs = showBreadcrumbs && !!breadcrumbs;

    const isNewStage =
        isMydays && showBreadcrumbs === false && featureFlagsVariation?.guided_search === 'on';

    return (
        <>
            <Box className={classnames('w-100', styles.storeHeader)}>
                <header className={classnames(styles.header, fullscreenOpen && styles.aboveNav)}>
                    <CartContextProvider>
                        <TopHeader />
                        <AlgoliaContextProvider client={searchClient}>
                            <RedemptionContextProvider>
                                <ActionHeader
                                    onFullscreenChange={setFullscreenOpen}
                                    isNewStage={isNewStage}
                                />
                            </RedemptionContextProvider>
                        </AlgoliaContextProvider>
                        <DesktopNavigation toggleBackdrop={setShowBackdrop} />
                    </CartContextProvider>
                </header>
            </Box>
            <Backdrop showBackdrop={showBackdrop} />
            {canRenderBreadcrumbs && <HeaderBreadcrumbs />}
            {!isSSR && <OrganisationMarkup url={window.location.origin} />}
            {canRenderBreadcrumbMarkup && <BreadcrumbMarkup markupData={breadcrumbs} />}

            {isNewStage && (
                <Box
                    className={classnames('w-100', styles.storeHeader, styles.newStageSotreHeader)}
                >
                    <div className="position-relative">
                        <Image
                            alt="Stage image"
                            src={stageImage}
                            className={classnames('w-100', styles.stageImage)}
                            lazyLoad={false}
                        />

                        <div
                            className={classnames(
                                'position-absolute start-0 w-100 bottom-0 p-3x p-xs-6x',
                                styles.stageSearchBar,
                            )}
                        >
                            <h1 className={classnames(styles.stageTitle, 'pb-0 pb-md-0-5x')}>
                                <FormattedMessage
                                    defaultMessage="Schenke gemeinsame Zeit, {br} die in Erinnerung bleibt."
                                    values={{ br: <br /> }}
                                />
                            </h1>
                            <AlgoliaContextProvider client={searchClient}>
                                <RedemptionContextProvider>
                                    <ActionHeader
                                        onFullscreenChange={setFullscreenOpen}
                                        isNewStage
                                        searchBarOnly
                                    />
                                </RedemptionContextProvider>
                            </AlgoliaContextProvider>
                        </div>
                    </div>
                </Box>
            )}
        </>
    );
};

export { StoreHeader };
