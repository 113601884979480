import {
    type FormEvent,
    type KeyboardEvent,
    type MouseEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import loadable from '@loadable/component';
import classNames from 'classnames';

import { type AlgoliaError } from '@jsmdg/algolia-helpers';
import { AlgoliaAnalyticsDataSource, getAlgoliaAnalyticsStorage } from '@jsmdg/browser-storage';
import { logToSentry } from '@jsmdg/react-fragment-scripts/fragment';
import {
    AlgoliaAnalyticsEventAction,
    AlgoliaAnalyticsEventPage,
    AlgoliaAnalyticsEventSource,
    SearchType,
    sendAlgoliaObjectIdsClickedAfterSearchEvent,
    trackEngagementSearch,
    trackSubmitSearch,
    trackUserSearchTerm,
} from '@jsmdg/tracking';
import { useClickOutside } from '@jsmdg/yoshi';
import { type Page, type Product } from '../../../shared/types';
import { focusAndOpenKeyboard } from '../../helper/focusAndOpenKeyBoard';
import { isQuotaExceededError } from '../../helper/isQuotaExceededError';
import { getSearchHistory, saveSearchHistory } from '../../helper/searchHistory';
import { useInsightsClient } from '../../hooks/useInsightsClient';
import { BackButton } from '../BackButton/BackButton';
import { CloseButton } from '../CloseButton/CloseButton';
import { SearchBarButton } from '../SearchBarButton/SearchBarButton';
import { type } from './typewriter';
import styles from './SearchBar.module.scss';

const LoadableFlyout = loadable(async () => import('../SearchFlyout/SearchFlyout'));

enum EventKeyCodes {
    Enter = 'Enter',
    Escape = 'Escape',
}

type SearchBarProps = {
    readonly serpUrl: string;
    readonly searchTerm: string;
    readonly changedHandler: (value: string) => void;
    readonly closeHandler?: () => void;
    readonly openHandler?: () => void;
    readonly showFlyout?: boolean;
    readonly showFlyoutDesktop?: boolean;
    readonly handleShowFlyout?: (show: boolean) => void;
    readonly handleSearch: (inputSearchTerm: string) => void;
    readonly isA11yHidden?: boolean;
    readonly layoutClassName?: string;
    readonly isMobileSearchOpen?: boolean;
    readonly productResults?: {
        indexName: string;
        results: Product[];
        queryId?: string;
    };
    readonly pageResults?: {
        indexName?: string;
        results: Page[];
        queryId?: string;
    };
    readonly algoliaError?: AlgoliaError;
    readonly isNewStage?: boolean;
    readonly isDesktop?: boolean;
};

const messages = defineMessages({
    searchBarHint: {
        defaultMessage: 'Suche nach Erlebnissen, Orten...',
    },
    searchSuggestionExperiences: {
        defaultMessage: 'Suche nach Erlebnissen...',
    },
    searchSuggestionPlaces: {
        defaultMessage: 'Suche nach Orten...',
    },
    searchSuggestionCategories: {
        defaultMessage: 'Suche nach Kategorien...',
    },
});

const WRITING_SPEED = 50;
const DELETION_SPEED = 50;

const SearchBar = ({
    algoliaError,
    changedHandler,
    closeHandler,
    handleSearch,
    handleShowFlyout,
    isA11yHidden = false,
    isDesktop,
    isMobileSearchOpen = false,
    isNewStage,
    layoutClassName,
    openHandler,
    pageResults = { results: [] },
    productResults = {
        results: [],
        indexName: '',
    },
    searchTerm,
    serpUrl,
    showFlyout = false,
    showFlyoutDesktop = false,
}: SearchBarProps): JSX.Element => {
    const form = useRef<HTMLFormElement>(null);
    const input = useRef<HTMLInputElement | null>(null);
    const searchOverlay = useRef<HTMLDivElement>(null);
    const [savedTerm, setSavedTerm] = useState('');
    const [searchHistory, setSearchHistory] = useState(getSearchHistory());
    const insightsClientPromise = useInsightsClient();

    const intl = useIntl();

    const sequence = useMemo(
        () => [
            intl.formatMessage(messages.searchSuggestionExperiences),
            1_000,
            intl.formatMessage(messages.searchSuggestionPlaces),
            1_000,
            intl.formatMessage(messages.searchSuggestionCategories),
            1_000,
        ],
        [intl],
    );

    const handleSearchTermChange = (): void => {
        if (input.current) {
            const { value } = input.current;
            setSavedTerm(value);
            changedHandler(value);
            handleSearch(value);
        }
    };

    const addToHistory = (title: string, link?: string): void => {
        if (title) {
            const filteredHistory = searchHistory.filter(
                historyItem => historyItem.title !== title,
            );
            setSearchHistory(
                [{ title, link }, ...filteredHistory].filter((item, index) => index < 3),
            );
        }
    };

    const handleOnClick = (e?: MouseEvent) => {
        e?.preventDefault();
        if (openHandler && isNewStage && !isDesktop) openHandler();
    };

    const navigateToSERP = (): void => {
        if (isNewStage && !isDesktop) {
            handleOnClick();
            return;
        }

        addToHistory(searchTerm);
        trackUserSearchTerm(searchTerm);
        trackSubmitSearch(
            savedTerm,
            SearchType.TypeAndEnter,
            savedTerm,
            `${serpUrl}?q=${savedTerm}`,
            pageResults.results.length,
        );
        form.current?.submit();
    };

    const handleFocus = (): void => {
        if (handleShowFlyout) {
            handleShowFlyout(true);
        }

        input.current?.focus();

        if (isDesktop && isNewStage) {
            window.scrollTo({
                top: 400,
                behavior: 'smooth',
            });
        }
    };

    const trackAlgoliaProductClick = async (
        position: number,
        objectId: string,
        indexName: string,
        queryId: string,
    ): Promise<void> => {
        const insightsClient = (await insightsClientPromise.current)?.default;
        if (insightsClient) {
            try {
                sendAlgoliaObjectIdsClickedAfterSearchEvent({
                    insightsClient,
                    eventName: {
                        page: AlgoliaAnalyticsEventPage.SearchFlyout,
                        source: AlgoliaAnalyticsEventSource.SearchFlyout,
                        action: indexName.includes('product')
                            ? AlgoliaAnalyticsEventAction.ProductClicked
                            : AlgoliaAnalyticsEventAction.PageClicked,
                    },
                    indexName,
                    queryId,
                    objectIds: [objectId],
                    positions: [position],
                });
            } catch {
                if (algoliaError) logToSentry(algoliaError);
            }

            const algoliaAnalyticsStorage = getAlgoliaAnalyticsStorage();
            try {
                algoliaAnalyticsStorage.add(objectId, AlgoliaAnalyticsDataSource.SEARCH_FLYOUT, {
                    indexName,
                    queryId,
                    timestamp: Date.now(),
                });
            } catch (error_) {
                const error = error_ as Error;
                if (isQuotaExceededError(error)) {
                    logToSentry(
                        `${error.message}; AlgoliaAnalytics entries: ${
                            Object.keys(algoliaAnalyticsStorage.get()).length
                        }`,
                    );
                }
            }
        }
    };

    /* eslint max-params: [0] */
    const handleSuggestionClick = (
        suggestionTitle: string,
        suggestionLink: string,
        position?: number,
        objectId?: string,
        indexName?: string,
        queryId?: string,
    ): void => {
        addToHistory(suggestionTitle, suggestionLink);
        trackEngagementSearch(savedTerm);
        trackUserSearchTerm(suggestionTitle);

        if (position && objectId && indexName && queryId)
            // eslint-disable-next-line no-void
            void trackAlgoliaProductClick(position, objectId, indexName, queryId);
    };

    // Puts searchbar in focus on render if mobile and opens the keyboard on both iOS and Android devices
    // Source: https://blog.maisie.ink/react-ref-autofocus/#callback-refs
    const callbackRef = useCallback(
        (inputElement: HTMLInputElement): void => {
            input.current = inputElement;
            if ((!isMobileSearchOpen || isNewStage) && input.current) {
                // eslint-disable-next-line no-void
                void type(input.current, WRITING_SPEED, DELETION_SPEED, ...sequence, type);
            }

            if (isMobileSearchOpen && inputElement) {
                focusAndOpenKeyboard(inputElement, 150);
            }
        },
        [isMobileSearchOpen, isNewStage, sequence],
    );

    const submitForm = (e: FormEvent): void => {
        if (!searchTerm) {
            e.preventDefault();
        }
    };

    const hideFlyout = (): void => {
        if (handleShowFlyout) {
            handleShowFlyout(false);
        }
    };

    useEffect(() => {
        saveSearchHistory(searchHistory);
    }, [searchHistory]);

    useClickOutside(searchOverlay, (target?: EventTarget) => {
        if (!isMobileSearchOpen && target && !input.current?.isEqualNode(target as HTMLElement)) {
            hideFlyout();
            input.current?.blur();
        }
    });

    const handleKeyDown = (event: KeyboardEvent): void => {
        switch (event.key) {
            case EventKeyCodes.Enter:
                trackEngagementSearch(savedTerm);
                trackSubmitSearch(
                    savedTerm,
                    SearchType.TypeAndEnter,
                    savedTerm,
                    `${serpUrl}?q=${savedTerm}`,
                    pageResults.results.length,
                );
                addToHistory(savedTerm);
                form.current?.submit();
                break;
            case EventKeyCodes.Escape:
                hideFlyout();
                break;
            default:
                break;
        }
    };

    const clearInput = (): void => {
        changedHandler('');
        handleSearch('');
        hideFlyout();
        setSavedTerm('');
        input.current?.focus();
    };

    const formWrapperStyles = classNames(
        'mr-md-5x mr-xl-10x flex-grow-1',
        styles.searchBar,
        !isNewStage ? layoutClassName : '',
        {
            [styles.newStageSearchBar]: isNewStage,
        },
    );

    const formContent = (
        <form
            method="get"
            ref={form}
            className={classNames('d-flex position-relative align-items-center', styles.form)}
            onSubmit={submitForm}
            action={serpUrl}
        >
            <input
                name="q"
                className={classNames('w-100 position-relative', styles.input)}
                ref={callbackRef}
                data-testid="search-input-field"
                type="text"
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                placeholder={
                    isMobileSearchOpen
                        ? intl.formatMessage(messages.searchBarHint)
                        : intl.formatMessage(messages.searchSuggestionExperiences)
                }
                value={searchTerm}
                enterKeyHint="search"
                autoComplete="off"
                onChange={handleSearchTermChange}
                onClick={handleOnClick}
            />
            <div className={classNames('d-flex position-absolute', styles.buttonWrapper)}>
                {searchTerm && (
                    <CloseButton handleOnClick={clearInput} isNewStage={isNewStage && isDesktop} />
                )}
                <SearchBarButton handler={navigateToSERP} isNewStage={isNewStage} />
            </div>
        </form>
    );

    const flyoutContent = (
        <LoadableFlyout
            searchResults={{
                products: searchTerm ? productResults : { results: [], indexName: '' },
                pages: searchTerm ? pageResults : { results: [] },
            }}
            onItemClick={handleSuggestionClick}
            addToHistory={addToHistory}
            searchHistory={searchHistory}
            setSearchHistory={setSearchHistory}
            overlayRef={searchOverlay}
            isMobile={!isDesktop}
            searchTerm={searchTerm}
            isNewStage={isNewStage}
        />
    );

    if (isMobileSearchOpen) {
        return (
            <div
                className={classNames(
                    styles.wrapper,
                    'd-md-none w-100 h-100 top-0 start-0 position-fixed overflow-scroll py-2x',
                )}
            >
                <div className="d-flex align-items-center px-xs-4x px-3x">
                    {closeHandler && <BackButton handler={closeHandler} />}
                    <div
                        className={formWrapperStyles}
                        ref={searchOverlay}
                        aria-hidden={isA11yHidden}
                    >
                        {formContent}
                    </div>
                </div>
                <div className="mt-2x">{showFlyout && flyoutContent}</div>
            </div>
        );
    }

    return (
        <div className={formWrapperStyles} ref={searchOverlay} aria-hidden={isA11yHidden}>
            {formContent}
            {isDesktop && showFlyoutDesktop && flyoutContent}
        </div>
    );
};

export { SearchBar };
